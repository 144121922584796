import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import Layout from '../components/layout';
import Seo from '../components/seo';
import TopBar from '../components/top-bar/top-bar';
import {
  Button, Container, Section, Wrapper, Image, SubTitle,
} from '../styles/servicios.elements';

const ServicePage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPages(title: {eq: "Servicios"}) {
        title
        images {
          gatsbyImageData(width: 360, height: 240)
        }
      }
    }
  `);
  const { images } = data.contentfulPages;
  return (
    <Layout>
      <main>
        <TopBar title="NUESTROS SERVICIOS" imageName="nosotros.jpg" />
        <Section>
          <Wrapper>
            <Container width="32%">
              <Image image={getImage(images[0]) as IGatsbyImageData} alt="Cableado en centro de computo" />
              <SubTitle>CABLEADO ESTRUCTURADO/ DATACENTER</SubTitle>
              <p>
                Identificamos las necesidades de su empresa y asesoramos la mejor solución en sistemas de redes de cableado estructurado. Diseñamos una solución integral que permita satisfacer sus necesidades actuales y futuras, incluyendo tanto equipos activos, tales como switch, router, servidor.
              </p>
              <Button to="/servicios/cableado-estructurado" data-icon="P">Ver mas <BsPlusCircle className="plus" /></Button>
            </Container>

            <Container width="32%">
              <Image image={getImage(images[1]) as IGatsbyImageData} alt="Verificacion de tablero electrico" />
              <SubTitle>SERVICIO DE REDES ELÉCTRICAS E ILUMINACIÓN</SubTitle>
              <p>
                Diseñamos sistemas eléctricos eficientes, seguros y a la medida. Ofrecemos alternativas innovadoras con soluciones viables de costo-beneficio, cumpliendo con la normatividad vigente. Diseño e instalacion de sistemas de iluminacion en cumplimiento de la norma RETILAP.
              </p>
              <Button to="/servicios/redes-electricas" data-icon="P">Ver mas <BsPlusCircle className="plus" /></Button>
            </Container>

            <Container width="32%">
              <Image image={getImage(images[2]) as IGatsbyImageData} alt="Camara de seguridad tipo domo" />
              <SubTitle>SERVICIO EN SISTEMAS ELECTRÓNICOS DE SEGURIDAD</SubTitle>
              <p>
                Proyectos e instalaciones para establecimientos industriales y corporativos que incluyen el analisis tecnico, diseño, instalacion y configuracion de los dispositivos que conforman el sistema de seguridad como lo son camaras de seguridad, grabadores de video, alarmas y biometricos.
              </p>
              <Button to="/servicios/seguridad">Ver mas <BsPlusCircle className="plus" /></Button>
            </Container>

            <Container width="32%">
              <Image image={getImage(images[3]) as IGatsbyImageData} alt="Servicios obra civil, sistemas de aire acondicionado y mobiliaria" />
              <SubTitle>OTROS SERVICIOS</SubTitle>
              <p>
                Servicios integrales que pueden ser ejecutados en conjunto con las instalaciones de redes electricas y redes de cableado estructurado como lo son obra civil, mobiliaria para oficina, sistemas de red contra incendios y aire acondicionado.
              </p>
              <Button to="/servicios/otros-servicios" data-icon="P">Ver mas <BsPlusCircle className="plus" /></Button>
            </Container>
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default ServicePage;

export const Head: HeadFC = () => <Seo title="Servicios" />;
